import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

import campusmap from "/src/images/campus-map.png"
import aed from "/src/images/aed.gif"


const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
    {
      pathname: '/campus/',
      crumbLabel: '筑波キャンパスについて'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 

  return(
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("所内施設")} lang={lang} />

      <ContentLayout lang={lang}>
        <h1>{t("所内施設")}</h1>
        <img className="" src={ campusmap } alt={t("所内施設地図")}  className="img-wide"/>
        <p>
          <Assets 
            data={data}
            name="campus-map.pdf"
            >
            {t("所内施設")}
          </Assets>
        </p>
        <h2>{t("所内施設一覧")}</h2>
        <ul className="facilities-list">
          <li>1. {t("守衛所")}</li>
          <li>2. {t("車庫棟")}</li>
          <li>3. {t("事務棟")}</li>
          <li>4. {t("食堂")}</li>
          <li>5. {t("外来者宿泊施設 A棟・B棟")}</li>
          <li>6. {t("情報・微生物棟")} 1</li>
          <li>7. {t("情報・微生物棟")} 2</li>
          <li>8. {t("研究棟(第１期)")}</li>
          <li>9. {t("研究棟(第２期)")}</li>
          <li>10. {t("バイオリソース棟")}</li>
          <li>11. {t("細胞研究リソース棟")}</li>
          <li>12. {t("実験動物維持施設")}</li>
          <li>13. {t("組換えDNA実験棟")}</li>
          <li>14. {t("ヒト疾患モデル開発研究棟")}</li>
          <li>15. {t("エネルギー棟")}</li>
          <li>16. {t("解析研究棟")}</li>
        </ul>
        <p className="aed">
          <img  src={ aed } alt=""  className="img-wide"/>
          <span>{t("AED設置場所")}</span>
        </p>

      </ContentLayout>
    </Layout>
  )
}
export default Page

export const query = graphql`
  query($language: String!) {
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

